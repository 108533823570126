import { MatDialogRef } from '@angular/material';
import { Component } from '@angular/core';

@Component({
  selector: 'app-confirm',
  template: `<h3 md-dialog-title>{{ title }}</h3>
    <div md-dialog-content style="margin-bottom: 15px;">{{ message }}</div>
    <div md-dialog-actions>
    <button
    type="button"
    md-raised-button
    color="primary"
    (click)="dialogRef.close(true)" class="primary-btn">OK</button>
    &nbsp;
    <span fxFlex></span>
    <button
    type="button"
    color="accent"
    [hidden]="hideClose"
    md-raised-button
    (click)="dialogRef.close(false)" class="primary-btn">Cancel</button>
    </div>`,
})
export class AppComfirmComponent {

  public title: string;
  public message: string;
  public hideClose: boolean;

  constructor(public dialogRef: MatDialogRef<AppComfirmComponent>) {

  }
}
