import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material';
import { Injectable } from '@angular/core';

import { AppComfirmComponent } from './app-confirm.component';
import { AppComfirmLoginComponent } from './app-confirm-login.component';

@Injectable()
export class AppConfirmService {

  constructor(private dialog: MatDialog) { }

  public confirm(title: string, message: string, hideClose: boolean = false): Observable<boolean> {
    let dialogRef: MatDialogRef<AppComfirmComponent>;
    dialogRef = this.dialog.open(AppComfirmComponent, { disableClose: true });
    dialogRef.updateSize('380px');
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.hideClose = hideClose;
    return dialogRef.afterClosed();
  }

  // public askForAuthentication(): Observable<boolean> {
  //   let dialogRef: MatDialogRef<AppComfirmLoginComponent>;
  //   dialogRef = this.dialog.open(AppComfirmLoginComponent, { disableClose: true });
  //   dialogRef.updateSize('380px');
  //   return dialogRef.afterClosed();
  // }
}
