import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule,NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'; // RH-2023-07-20
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/* Application Services */
import { UtilitiesService } from './shared/services/utilities.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { PageHeaderComponent } from './shared/modules/page-header/page-header.component';
// import { PageHeaderModule } from './shared/modules/page-header/page-header.module';
import { AuthGuard } from './shared';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTabsModule} from '@angular/material/tabs';
import { AppConfirmService } from './shared/services/app-confirm/app-confirm.service';

//FOLLOWING ... added to fix problem compiling, error = 'cannot bind <div> to ngIf ...'; this plus adding import below fixed the problem :()
// import { QtArcListModule } from './layout/quote-archive/qt-arc-list/qt-arc-list.module';

// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
    /* for development
    return new TranslateHttpLoader(
        http,
        '/start-angular/SB-Admin-BS4-Angular-6/master/dist/assets/i18n/',
        '.json'
    ); */
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatProgressBarModule,
        MatTabsModule,
        // PageHeaderModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule
        // QtArcListModule
    ],
    schemas: [  // RH-2023-07-20 added
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
      ],
    declarations: [AppComponent], 
    providers: [AuthGuard, AppConfirmService],
    bootstrap: [AppComponent]
})
export class AppModule {}
